$width: 400px;
$btnSize: 60px;

.right-panel {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: $width;
  overflow: auto;

  .open-btn {
    position: absolute;
    background: #4000ff;
    color: #fff;
    height: $btnSize;
    width: $btnSize;
    border-radius: $btnSize / 2;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 45px;
    right: 30px;
  }

  .inner {
    width: 100%;
    top: 0;
    right: 0;
    height: 100%;
    overflow: auto;
    position: absolute;
    z-index: 99999;
    background: #4000ff linear-gradient(210deg, #4000ff 6%, #4000ff 35%, #1d0055);
  }

  .exit {
    width: $width;
  }

  .exit-active {
    width: 0;
    transition: width 400ms;
    overflow: hidden;
  }
}

