@font-face {
  font-family: MaisonNeueLight;
  src: url('../fonts/MaisonNeueWEB-Light.woff2') format('woff2'),
  url('../fonts/MaisonNeueWEB-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MaisonNeueBook;
  src: url('../fonts/MaisonNeueWEB-Book.woff2') format('woff2'),
  url('../fonts/MaisonNeueWEB-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MaisonNeueDemi;
  src: url('../fonts/MaisonNeueWEB-Demi.woff2') format('woff2'),
  url('../fonts/MaisonNeueWEB-Demi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MaisonNeueBold;
  src: url('../fonts/MaisonNeueWEB-Bold.woff2') format('woff2'),
  url('../fonts/MaisonNeueWEB-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}