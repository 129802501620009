.sets {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.loader {
  margin-left: 10px;
  font-size: 30px;
}

.title {
  text-align: center;
  margin: 30px;
  font-size: 30px;
}

.header {
  border-bottom: 1px solid black;
  margin-bottom: 25px;

  h2 {
    font-size: 22px;
    padding-bottom: 20px;
  }
}

.body {
  flex-grow: 1;
  overflow: hidden;
}

.header .inside,
.footer .inside,
.body {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

.list {
  &.withDefault {
    position: relative;
    left: -30px;
  }
}

.footer {
  border-top: 1px solid black;
  margin-top: 25px;

  .inside {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }
}