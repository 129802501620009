.color {
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 2px;
  margin-bottom: 2px;
  padding: 2px;
  border: 1px solid #eee;

  &> .inside {
    width: 100%;
    height: 100%;
    display: block;
  }

  &.active {
    border: 1px solid var(--blue);
  }

  &:last-child {
    margin-right: 0;
  }
}