.button {
  position: relative;
  color: #fff;
  height: 3em;
  border-radius: 2.5em;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  padding: 0 15px;
  background: var(--blue);
  font-weight: bold;

  &:global {
    &.btn-secondary:focus,
    &.btn-secondary:hover,
    &.btn-secondary {
      background: transparent;
      color: var(--blue);
      border: 1px solid var(--blue);
      box-shadow: none;
    }
  }
}

.size-sm {
  font-size: 12px;
  height: 2.5em;

  .icon {
    width: 2.5em;
  }

  .icon,
  .title {
    border-radius: 1.5em;
  }
}

.size-normal {
  font-size: 14px;
  padding: 0 30px;
  height: 3em;
}

.size-lg {
  font-size: 18px;
}