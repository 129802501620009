$width: 259px;
$btnSize: 60px;

.left-panel {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: $width;
  overflow: auto;

  .inner {
    height: 100%;
    overflow: auto;
    position: relative;
    z-index: 99999;
    background: #4000ff linear-gradient(210deg, #4000ff 6%, #4000ff 35%, #1d0055);
  }

  .exit {
    width: $width;
  }

  .exit-active {
    width: 0;
    transition: width 400ms;
    overflow: hidden;
  }
}

