.toggle {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;

  &.active {
    border: 1px solid var(--blue);
  }

  &:last-child {
    margin-right: 0;
  }
}