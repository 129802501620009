$size: 15px;

.button {
  position: absolute;
  cursor: ew-resize !important;
  width: $size;
  height: $size;
  top: 50%;
  border-radius: $size / 2;
  margin-top: $size / -2;
  border: none;
}

.position-right {
  right: $size / -2 + 2;
}

.position-left {
  left: $size / -2 + 2;
}

.color-violet {
  background: var(--violet);
}

.color-lightblue {
  background: var(--lightblue);
}