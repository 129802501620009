.button {
  position: relative;
  color: #fff;
  height: 3em;
  border-radius: 2.5em;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  padding: 0;

  &:hover .title {
    display: flex;
  }
}

.icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 100%;
}

.title {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: inherit;
  top: 0;
  height: 100%;
  border-radius: 2.5em;
  padding: 0 1.5em;
  pointer-events: none;
}

.direction-left {
  .title {
    right: 0;
    padding-right: 2.8em;
  }
}

.direction-right {
  .title {
    left: 0;
    padding-left: 2.8em;
  }
}

.size-sm {
  font-size: 12px;
  height: 2.5em;

  .icon {
    width: 2.5em;
  }

  .icon,
  .title {
    border-radius: 1.5em;
  }
}

.size-lg {
  font-size: 18px;
}

.color-blue {
  background: var(--blue);
}

.color-lightblue {
  background: var(--lightblue);
}

.color-red {
  background: var(--red);
}

.color-violet {
  background: var(--violet);
}