@import "../../assets/scss/variables";

.tooltip {
  top: -$subheaderHeight - $headerHeight;
  left: 0;
  visibility: hidden;

  :global {
    .tooltip-inner {
      max-width: none;
      padding: 0;
      border-radius: 0;
    }

    .tooltip-arrow {
      left: 50%;
      margin-left: -5px;

      &:before {
        border-top-color: var(--blue)
      }
    }

    .btn {
      border-radius: 0;
      background: var(--blue);
      border: none;
      margin: 0;
    }
  }

  .hide {
    visibility: hidden;
  }
}