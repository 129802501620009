$control-offset: 40px;

.wrapper {
  position: relative;
  cursor: pointer;
}

.control {
  z-index: 1;
  position: absolute;
  top: -$control-offset;
  left: -$control-offset;
  width: calc(100% + #{$control-offset * 2});
  height: calc(100% + #{$control-offset * 2});
}

.hoverLayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:hover {
    background: rgba(201, 181, 238, 0.1);
  }
}

.inside {
  position: relative;
}

.active {
  .hoverLayer {
    display: none;
  }

  .inside {
    z-index: 1;
  }

  .control {
    background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23C9B5EEFF' stroke-width='4' stroke-dasharray='6%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    z-index: 1;
  }
}