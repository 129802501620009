.dialog {
  margin-top: 60px;
}

.content {
  &:global {
    & > .modal-header {
      border: 0;
      padding: 20px;
    }

    & > .modal-footer {
      border: 0;
    }

    & > .modal-body {
      .form-control {
        border: 1px solid #000;
      }

      .form-check-input {
        border: 1px solid #000;
      }
    }
  }
}

.backdrop {
  z-index: 1055;
}

.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}