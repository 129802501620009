@import "../../common/assets/scss/variables";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  user-select: none;
  background: #4000ff linear-gradient(
                  120deg, #4000ff 6%, #4000ff 35%, #1d0055);
  color: #fff;
  height: $headerHeight;

  .btnGroup {
    padding-left: 12px;
    padding-right: 12px;

    button {
      padding-left: 6px;
      padding-right: 6px;
      color: #fff;
      text-decoration: none;
      box-shadow: none !important;

      &:hover {
        color: #43CFF3;
      }
    }
  }

  .left {
    flex-grow: 1;
    width: 50%;
    padding-left: 2px;
  }

  .right {
    flex-grow: 1;
    width: 50%;
    text-align: end;
    padding-right: 5px;
  }
}

.subheader {
  flex-grow: 0;
  flex-shrink: 0;
  background: #282828;
  height: $subheaderHeight;
}

.body {
  display: flex;
  flex-grow: 1;
  position: relative;
  overflow: auto;

  .content {
    display: flex;
    flex-grow: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
  }
}

.footer {
  flex-grow: 0;
  flex-shrink: 0;
  background: #282828;
  height: 32px;
}

.logo {
  flex-shrink: 0;
  height: 20px;
}