.item {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;

  &.withDefault {
    padding-left: 30px;

    &.isDefault {
      padding-left: 0;
    }

    .default {
      width: 30px;
      color: var(--violet);
    }
  }

  &:hover,
  &.isActive {
    color: var(--blue);

    .button {
      display: block;
    }
  }
}

.button {
  display: none;
  margin-left: 10px;
  padding: 0 !important;
  height: 24px;
}

.children {
  max-width: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 1;
  margin-right: 5px;
}
