* {
  user-select: none;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: MaisonNeueDemi, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.break-word {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.field.resize {
  background: #43CFF3;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.droppable {
  background: #c9b5ee;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #282828;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 2px;
  border: 2px solid #282828;
}

.field-drag {
  border-bottom: 1px solid #282828;
  height: 48px;
  display: flex;
  color: #fff;
  padding-left: 19px;
}

.drag-overlay .new {
  background: #4000ff;
  color: #fff;
}


.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

