$presetSize: 25px;

.ColorPicker {
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 198px;

  .input {
    margin-top: 10px;

    input {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      -webkit-appearance: none;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  .pointers {
    display: flex;
    flex-direction: row;
    height: 150px;
    width: 100%;
  }

  .saturation {
    position: relative;
    height: 100%;
    flex-grow: 1;
    margin-right: 8px;
    cursor: pointer;
    border: 1px solid #767676;
  }

  .hue {
    position: relative;
    width: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    cursor: pointer;
    border: 1px solid #767676;

    .pointer {
      width: 12px;
      height: 4px;
      border-radius: 7px;
      left: 3px;
      top: -2px;
      position: relative;
      box-shadow: rgb(255, 255, 255) 0 0 0 1.5px, rgba(0, 0, 0, 0.3) 0 0 1px 1px inset, rgba(0, 0, 0, 0.4) 0 0 1px 2px;
    }
  }
}