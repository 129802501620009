.item {
  display: flex;
  width: 100%;
  align-items: center;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 5px;
}

.hex {
  flex-shrink: 0;
  flex-grow: 0;
  width: 80px;
}

.position {
  flex-grow: 0;
  flex-shrink: 0;
  width: 30px;
}

.preview {
  flex-shrink: 0;
  flex-grow: 0;
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid #aaa;
  margin-right: 5px;
}