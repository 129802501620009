$offset: 14px;

.button {
  position: absolute;
}

.top-left {
  top: -$offset;
  left: -$offset;
}

.top-right {
  top: -$offset;
  right: -$offset;
}

.bottom-left {
  bottom: -$offset;
  left: -$offset;
}

.bottom-right {
  bottom: -$offset;
  right: -$offset;
}