$corner-size: 30px;
$side-size: 15px;
$control-offset: 20px;

.wrapper {
  position: relative;
  cursor: pointer;

  input {
    pointer-events: none;
  }

  &:not(.active):hover > .control {
    background: rgba(67, 207, 243, 0.1);
  }
}

.control {
  position: absolute;
  top: -$control-offset;
  left: -$control-offset;
  width: calc(100% + #{$control-offset * 2});
  height: calc(100% + #{$control-offset * 2});
}

.inside {
  position: relative;
  z-index: 1;
}

.active {
  & > .control {
    background: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2343CFF3FF' stroke-width='4' stroke-dasharray='6%2c10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    z-index: 10;
  }

  & > .inside {
    z-index: 20;
  }
}